<template>
  <div class="house-list-container">
    <Header :search="true" :info='searchInfo' @update-active='doUpdateActive' @search="doSearch" />
    <FilterInfo ref="filterInfo" :type="5" @change="doChangeFilter" />
    <HouseDealDetail
      @update="doFindHouseList"
      :total="total"
      :list="dataList"
      :recommedList="recommedList"
      @sizeChange="doSizeChange"
      @pageChange="doPageChange"
    />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import FilterInfo from "@/components/FilterInfo.vue";
import HouseDealDetail from "@/components/HouseDealDetail.vue";
export default {
  name: "HouseDeal",
  components: { Header, FilterInfo, HouseDealDetail },
  data() {
    return {
      searchInfo:null,
      params: {
        pageSize: 1,
        pageNum: 10,
        areaInfo: "",
        sortType: 0,
        type:"wyd",
        isRecommend: null,
      },
      dataList: [],
      recommedList: [],
      total: 0,
    };
  },
  mounted() {
    this.searchInfo = this.$route.query
    this.doFindHouseList();
    this.doFindRecommendList();
  },
  methods: {
    goBackTop() {
      const timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    doPageChange(page) {
      this.params.pageSize = page;
      this.doFindHouseList();
      this.goBackTop();
    },
    doSizeChange(size) {
      this.params.pageNum = size;
      this.doFindHouseList();
      this.goBackTop();
    },
    doSearch(keywords) {
      this.params.areaInfo = keywords;
      this.doFindHouseList();
    },
    doChangeFilter(params) {
      this.params = Object.assign({}, this.params, params);
      this.doFindHouseList();
    },
    doUpdateActive() {
      this.dataList = [];
      this.$refs["filterInfo"].params.city = [];
      this.params.city = [];
      this.doFindHouseList();
    },
    doFindRecommendList() {
      this.$request
        .get("/api/house/queryRecommendMiddlemanList")
        .then((res) => {
          if (res.code === 200) {
            this.recommedList = res.data;
          } else {
            this.recommedList = [];
          }
        });
    },
    doFindHouseList() {
      this.$request
        .post(
          `/api/houseDeal/queryHouseDeal?pageSize=${this.params.pageNum}&pageNum=${this.params.pageSize}`,
          this.params
        )
        .then((res) => {
          if (res.code === 200) {
            this.dataList = res.data.rows;
            this.total = res.data.total;
          } else {
            this.dataList = [];
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/variable.scss";
.house-list-container {
  width: 1200px;
  margin: 0 auto;
  .house-wrapper {
    margin-top: 20px;
  }
  .el-tabs--card > .el-tabs__header {
    border-bottom: 2px solid $mainColor;
  }
  .el-tabs__item:hover {
    color: $mainColor;
  }
  .el-tabs__item.is-active {
    color: #fff;
    background-color: $mainColor;
    border-color: $mainColor;
  }
  .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    border-color: $mainColor;
  }
  .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border-color: $mainColor;
    border-radius: 0;
  }
  .el-tabs--card > .el-tabs__header .el-tabs__item {
    border-color: $mainColor;
  }
}
</style>
