<template>
  <div class="house-container1">
    <div class="main-wrapper1">
      <div class="house-info-wrapper1">
        <p class="search-result1">
          共找到<span>{{ total }}</span
          >套房屋
        </p>
        <div class="house-item-wrapper1" v-for="tmp in list" :key="tmp.id">
          <div class="img">
            <img :src="tmp.imgUrl" alt="" />
          </div>
          <div class="info-item">
            <div class="info-item-tit">
              <div class="info-item-tit-lf">
                <div>
                  {{ tmp.name }}
                </div>
                <div>{{ tmp.shi }}室{{ tmp.ting }}厅</div>
              </div>
              <div
                v-if="!tmp.zj"
                class="info-item-tit-rt"
                @click="doGetprice(tmp)"
              >
                查看价格
              </div>
            </div>
            <div class="info-item-cont">
              <div class="info-item-cont-li">面积：{{ tmp.mj }}平米</div>
              <div class="info-item-cont-li">成交时间：{{ tmp.cjtime }}</div>
              <div class="info-item-cont-li" v-if="tmp.dj">
                单价：<span class="unit-price">{{ tmp.dj }}元/平米</span>
              </div>
              <div class="info-item-cont-li" v-if="tmp.zj">
                总价：<span class="price">{{ tmp.zj }}万</span>
              </div>
            </div>
          </div>
        </div>
        <div class="house-pagination1" v-if="list.length">
          <el-pagination
            style="margin-top: 20px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[5, 10, 15]"
            :page-size="10"
            layout="sizes, prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div class="recommend-weapper1">
        <p class="recommend-title1">推荐中介</p>
        <div
          class="recommed-item-wrapper1"
          v-for="tmp in recommedList"
          :key="tmp.id"
        >
          <img class="avatar1" :src="tmp.avatar" alt="" />
          <div class="info-item1">
            <p class="info-label1">姓名：</p>
            <span class="info-msg1">{{ tmp.userName }}</span>
          </div>
          <div class="info-item1">
            <p class="info-label1">星级：</p>
            <el-rate class="info-msg1" disabled v-model="tmp.starLv"></el-rate>
          </div>
          <div class="info-item1">
            <p class="info-label1">电话：</p>
            <span class="info-msg1">{{ tmp.phone }}</span>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="container">-->
<!--      <div class="centered-div">-->
<!--        <span class="spanTi">提示</span>-->
<!--        <span>是否支付1元查看成交总价及单价？</span>-->
<!--          <el-radio-group >-->
<!--            <el-radio :label="1">现金余额：{{ myPrice.availableAmount }}</el-radio>-->
<!--            <el-radio :label="2">梦币余额：{{ myPrice.signAmount }}</el-radio>-->
<!--          </el-radio-group>-->
<!--      </div>-->
<!--    </div>-->
    <el-dialog :visible.sync="dialogVisible" title="提示" width="35%">
      <p style="margin-bottom: 20px;">是否支付0.1元查看成交总价以及单价？</p>
      <el-radio-group v-model="radio">
        <el-radio label="1">梦币余额：{{ myPrice.signAmount }}</el-radio>
        <el-radio label="2">现金余额：{{ myPrice.availableAmount }}</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
<el-button @click="dialogVisible = false">取 消</el-button>
<el-button type="primary" @click="handleConfirm">确 定</el-button>
</span>
    </el-dialog>
  </div>

</template>

<script>
export default {
  name: "HouseContainer",
  data() {
    return {
      dialogVisible: false,
      radio:'1',
      tmp:{
        dj:0,
        zj:0,
        id:0
      },
      currentPage: 1,
      myPrice:{
        availableAmount:0,
        signAmount:0
      }
    };
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    recommedList: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    token() {
      return localStorage.getItem("token");
    },
  },

  methods: {


    doGetprice(tmp) {
      const token = localStorage.getItem("token");
      if (!token) {
        this.$message.warning("请先登录！");
        return;
      }
      this.tmp=tmp;
      this.$request.get("/api/user/getUserAccount").then((res) => {
        if (res.code === 200) {
          this.myPrice.availableAmount = res.data.availableAmount;
          this.myPrice.signAmount = res.data.signAmount;
          this.dialogVisible = true;
        }
      });
      },
      handleConfirm() {
        this.dialogVisible = false;
            this.$request
                    .post("/api/houseDeal/getHosePrice", {
                      houseId: this.tmp.id,
                      type:this.radio
                    })
                    .then((res) => {
                      if (res.code === 200) {
                        this.$message.success(res.msg);
                        this.tmp.dj = res.data.dj;
                        this.tmp.zj = res.data.zj;
                        //   this.doInitHouse();
                      } else {
                        this.$message.error(res.msg);
                      }
                      this.radio='1';
                    });
        },
    handleSizeChange(size) {
      this.$emit("sizeChange", size);
    },
    handleCurrentChange(page) {
      this.$emit("pageChange", page);
    },
    doLike(houseId) {
      if (!this.token) {
        this.$message.warning("请先登录！");
        return;
      }
      this.$request
        .post("/api/house/updateCanOrAtt", { houseId })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.$emit("update");
          } else {
            this.$message.warning(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/variable.scss";
.spanTi{
  float: left;
  margin: 20px;
  font-size: 25px;
}
.centered-div{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  background-color: antiquewhite;
  width: 550px;
  height: 170px;
}
.info-item {
  flex: 1;
  padding: 0 30px;
  &-tit {
    flex: 1;
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    &-lf {
      display: flex;
      font-weight: 700;
      color: #101d37;
      div {
        margin-right: 20px;
      }
    }
    &-rt {
      font-family: Hiragino Sans GB, Microsoft Yahei UI, Microsoft Yahei,
        \\5FAE\8F6F\96C5\9ED1, Segoe UI, Tahoma, "\5B8B\4F53   b8b\4F53", SimSun,
        sans-serif;
      color: #00c297;
      cursor: pointer;
    }
  }

  &-cont {
    &-li {
      margin-top: 20px;
    }

    .price {
      font-size: 24px;
      font-weight: bold;
      color: $redColor;
    }
    .unit-price {
      color: $redColor;
    }
  }
}

.house-container1 {
  .search-result1 {
    padding-top: 10px;
    font-size: 18px;
    font-weight: 400;
    border-top: 1px solid $defaultColor;
    span {
      font-size: 20px;
      font-weight: bold;
      color: $mainColor;
      margin: 0 5px;
    }
  }
  .main-wrapper1 {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .house-info-wrapper1 {
    flex: 1;
    .house-pagination1 {
      height: 80px;
    }
    .house-item-wrapper1 {
      display: flex;
      padding: 20px 0;
      border-bottom: 1px dashed $defaultColor;
      .img {
        cursor: pointer;
        width: 210px;
        height: 155px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    //   .right-info {
    //     text-align: right;
    //     padding-right: 40px;
    //     .price {
    //       font-size: 24px;
    //       font-weight: bold;
    //       color: $redColor;
    //     }
    //     .unit-price {
    //       color: $textColor;
    //     }
    //   }
  }
  .recommend-weapper1 {
    width: 300px;
    padding: 10px;
    border-left: 1px solid $defaultColor;
    border-top: 1px solid $defaultColor;
    .recommend-title1 {
      font-size: 18px;
      font-weight: bold;
    }
    .recommed-item-wrapper1 {
      text-align: center;
      border-bottom: 1px solid $defaultColor;
      margin-top: 20px;
      padding: 10px 20px;
      .avatar1 {
        width: 60px;
        height: 60px;
        margin-bottom: 15px;
      }
      .info-item1 {
        margin-bottom: 20px;
        text-align: left;
        .info-label1 {
          display: inline-block;
          text-align: left;
          font-size: 18px;
          font-weight: bold;
        }
        .info-msg1 {
          display: inline-block;
          margin-left: 20px;
          color: $textColor;
        }
      }
    }
  }
}
</style>
